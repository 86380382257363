<template>
    <div>
        <b-card>
            <b-row style=" margin-bottom: -10px;">
                <b-col md="12" style=" margin-bottom: -10px;">
                    <b-form-group>
                        <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama" @input="get_data_list"
                            :options="option" />
                    </b-form-group>
                </b-col>
                <b-col md="3" style=" margin-bottom: -10px;" v-if="items.length >0">
                    <!-- <b-button block variant="primary" size="sm">
                        <feather-icon icon="DownloadIcon" size="20" /> <strong>Download Laporan</strong></b-button> -->
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col>
                <b-card>
                    <rekap-tagihan :items = items></rekap-tagihan>
                </b-card>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import axios from '@/config/Axios'
    import {
        BRow,
        BCol,
        BCard,
        BFormGroup,
        BButton
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import RekapTagihan from './component/RekapTagihan.vue'
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            vSelect,
            BFormGroup,
            BButton,
            RekapTagihan
        },
        mixins :[Base],
        data() {
            return {
                selected: {
                    title: 'Pilih Periode Semester '
                },
                option: [ ],
                items : []

            }
        },
        mounted(){
            this.get_semester();
        },
        methods:{
              Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            async get_semester() {
                    const self = this;
                    await axios({
                        method: 'PUT',
                        url: 'api/laporan/rekap_tagihan/get_semester',
                        data: {
                          
                        },
                        headers: {
                          'Authorization': self.isAuthenticated
                        }
                      })
                      .then(function (response) {
                        self.option = response.data.result;
                        //self.rs_level = response.data.result;
            
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                  },
                  async get_data_list() {
                        const self = this;
                        await axios({
                            method: 'PUT',
                            url: '/api/laporan/rekap_tagihan/get_data_list',
                            data: {
                                id_semester : self.selected.id_semester
                            },
                            headers: {
                              'Authorization': self.isAuthenticated
                            }
                          })
                          .then(function (response) {
                            self.items = response.data.result; 
                  
                          }).catch(err => {
                            self.pesan = err.message;
                            self.notification('warning', "Error", err.message);
                          });
                      },
        }
    }
</script>

<style>

</style>