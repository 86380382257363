<template>
    <div> 
        <b-row style="margin-bottom:-5px;">
            <b-col md="3" sm="4" class="mb-1"  v-if="items.length > 1">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"></label>
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                </b-form-group>
            </b-col>
            <b-col md="6" sm="8" class="my-1">

            </b-col>
            <b-col md="3" class=''  v-if="items.length > 1">
                <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    </b-input-group>
                </b-form-group>
            </b-col>

        </b-row>
        <b-row  >
            <b-col cols="12">
                <b-table thead-class="hidden_header" striped hover responsive :per-page="perPage"
                    :current-page="currentPage" :items="items" :fields="fields" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template #cell(tagihan)="data">

                        <b-row class="match-height">
                            <b-col md="4">
                                <b-alert variant=" " show style="margin-left:-15px;">
                                    <div class="alert-body">
                                        <h4><small><strong>Periode Semester {{ data.item.nm_semester }}</strong></small>
                                            <br> {{  data.item.nm_jenis_pembayaran  }} ({{ data.item.kelas }})
                                        </h4>
                                        <b-row>
                                            <b-col md="12">
                                                <small>Periode tanggal pembayaran</small><br>
                                                {{ data.item.tgl_mulai }} - {{ data.item.tgl_selesai }}
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <p class="text-muted"><small>
                                                        <strong>Keterangan</strong><br>{{ data.item.keterangan }}</small>
                                                </p>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col><small><strong>Nominal Pembayaran </strong></small>
                                                <h3 class="text-warning"><sup>Rp</sup><strong>
                                                        {{Rp(data.item.nominal) }}</strong></h3>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-alert>
                            </b-col>

                            <b-col md="5" class="match-height">
                                <small>No Rekening</small>
                                <h6 class="">Bank {{ data.item.bank }} {{ data.item.no_rekening }}<br>
                                    <small>Atasnama : </small><br><strong> {{ data.item.atas_nama }}</strong>
                                </h6>

                                <div class="row mt-1">
                                    <div class="col-xl-6 col-sm-6 col-12 mb-2 mb-sm-0">
                                        <div class="media">
                                            <div class="media-body my-auto">
                                                <p class="card-text font-small-3  mb-0">Total Pengajuan</p>
                                                <h3 class="font-weight-bolder   mb-0">{{ data.item.jm_ttl }}</h3>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-sm-6 col-12 mb-2 mb-xl-0">
                                        <div class="media">
                                            <div class="media-body my-auto">
                                                <p class="card-text font-small-3  mb-0">konfirmasi Pembayaran</p>
                                                <h3 class="font-weight-bolder   mb-0">{{ data.item.jm_k }}</h3>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-sm-6 col-12 mb-2 mb-xl-0">
                                        <div class="media">
                                            <div class="media-body my-auto">
                                                <p class="card-text font-small-3  mb-0">Pembayaran Dikembalikan</p>
                                                <h3 class="font-weight-bolder   mb-0">{{ data.item.jm_r }}</h3>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-sm-6 col-12 mb-2 mb-xl-0">
                                        <div class="media">
                                            <div class="media-body my-auto">
                                                <p class="card-text font-small-3  mb-0"> Pembayaran Ditolak</p>
                                                <h3 class="font-weight-bolder   mb-0">{{ data.item.jm_d }}</h3>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </b-col>
                            <b-col md="3">
                                <b-alert variant=" " show style="margin-left:-15px;">
                                    <div class="alert-body">
                                        <b-row>
                                            <b-col>
                                                <h5 class="text-primary">Total Pembayaran tervalidasi <br>
                                                    <strong style="font-size: 25px;;">
                                                     <feather-icon icon="UsersIcon" size="20" /> 
                                                     <strong>
                                                        {{ data.item.jm_v }}
                                                     </strong><sub><small> , Mahasiswa</small></sub></strong></h5><br>
                                               
                                            </b-col>
                                        </b-row>
                                        <b-row style="margin-top: -20px;">
                                            <b-col>
                                                <small class="text-primary">Total Tagihan</small><br>
                                                <h1 class="text-primary" style="font-size: 30px;"><sup><small>Rp.
                                                        </small></sup><strong>{{ Rp(data.item.tertagih) }}</strong>
                                                </h1>
                                            </b-col>
                                        </b-row>
                                        <b-row style="margin-top: -10px;">
                                            <b-col>
                                                <small class="text-primary">Total Terbayar</small><br>
                                                <h1 class="text-primary" style="font-size: 30px;"><sup><small>Rp.
                                                        </small></sup><strong>{{ Rp(data.item.terbayar) }}</strong>
                                                </h1>
                                            </b-col>
                                        </b-row>
                                        <!-- <b-row class="mt-2">
                                            <b-col md="4">
                                                <b-avatar rounded="" size="50" variant="primary" class="mr-1">
                                                    <feather-icon icon="CheckCircleIcon" size="30" />
                                                </b-avatar>
                                            </b-col>
                                            <b-col md="8">
                                                <div class="media-body my-auto">
                                                    <h3 class="font-weight-bolder text-primary mb-0">
                                                        {{ data.item.jm_v }}</h3>
                                                    <p class="card-text font-small-3 text-primary mb-0">Tervalidasi</p>
                                                </div>
                                            </b-col>
                                        </b-row> -->
                                    </div>
                                </b-alert>
                            </b-col>
                        </b-row>

                        <!-- {{ data.item }} -->
                    </template>
                </b-table>
            </b-col>
            <div v-if="items.length < 1" class="text-center">
                <h5 class="text-center text-warning ml-3">***Maaf... Belum ada transaksi tervalidasi yang bisa kami sajikan, silahkan pilih periode semester
                    </h5>
            </div>
            <b-col cols="12" v-if="items.length > 1" >
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BAlert,
        BCard
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BAlert,
            BCard
        },
        props: {
            items: {}
        },
        data() {
            return {
                perPage: 5000,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],

                fields: [{
                    key: 'tagihan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }, ],

            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>